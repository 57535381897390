<template>
  <!-- 提货管理 买家-->
  <div class="page-card-demo page-info-content">
    <Tabs :active-name="formInline.state" :tabs-list="tabsList" @getTabName="getTabChange" />
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #goodsTwoCategoryName="{ row }">
          <div>
            {{ row.goodsTwoCategoryName }}/{{ row.goodsMaterialName }}/{{
              row.goodsSpecName
            }}
          </div>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Tabs from '@/components/Tabs.vue'
import Pagination from '@/components/Pagination2.vue'
import {
  tradeTakeGoodsBillPagePlatScmTakeGoodsBilll
} from '@/api/shoppingMall'
export default {
  components: { FormSearch, Table, Pagination, Tabs },
  data() {
    return {
      choiceRelatedOrder: null,
      tabsList: [
        { label: '全部提货单', name: '' },
        { label: '待支付', name: '00' },
        { label: '待商家审核', name: '02' },
        { label: '待出库', name: '04' },
        { label: '待提货确认', name: '05' },
        { label: '提货成功', name: '06' },
        { label: '已取消', name: '07' },
        { label: '已拒绝', name: '03' }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        state: ''
      },
      listData: [],
      total: 0,
      loading: false,
      activeName: '',
      searchFormItemArr: [
        { type: 'input', label: '提货单号', value: 'takeGoodsNo', width: '80px' },
        { type: 'input', label: '订单编号', value: 'orderNo', width: '80px' },
        {
          type: 'input',
          label: '仓库名称',
          value: 'warehouseName',
          width: '80px'
        },
        { type: 'input', label: '商家名称', value: 'sellName', width: '80px' },
        { type: 'input', label: '买家名称', value: 'buyerName', width: '80px' }
      ],
      itemData: [
        { label: '提货单号', prop: 'takeGoodsNo', width: 200 },
        { label: '关联订单号', prop: 'orderNo', path: '/shoppingMall/orderDetails', pathParameter: 'orderId', width: 260 },
        { label: '品名/材质/规格', prop: 'goodsTwoCategoryName', width: 200 },
        { label: '提货数量/单位', prop: 'applyTakeWeight', type: 'weight', dynamicUnit: 'unitMeasurement', unit: '吨', width: 120 },
        { label: '商家名称', prop: 'sellerName', width: 280 },
        { label: '买家名称', prop: 'buyerName', width: 280 },
        { label: '提货仓库', prop: 'warehouseName', width: 220 },
        { label: '预计提货日期', prop: 'prePickUpTime', width: 220 },
        {
          label: '状态',
          prop: 'state',
          width: 180,
          child: this.$store.getters.getDictionaryItem('take_goods_bill_status')
        }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ],
      selectAccount: null,
      paymentObj: {}
    }
  },
  methods: {
    //  获取分页数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          state: '',
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      tradeTakeGoodsBillPagePlatScmTakeGoodsBilll(this.formInline, (res) => {
        this.listData = [...res.data.records]
        this.total = res.data.total
      })
    },
    // 获取当前tab
    getTabChange(val) {
      if (!val || val === '0') { this.formInline.state = '' } else { this.formInline.state = val }
      this.getdata()
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/shoppingMall/pickingDetails',
        query: { id }
      })
    }
  }
}
</script>

<style scoped lang="scss">
:v-deep(.el-tabs__nav-wrap::after) {
  width: 0;
}
:v-deep(.el-tabs__active-bar) {
  display: none;
}
</style>
